"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.oldDataVizId = exports.certTypeTitleMap = exports.certTypeIdMap = exports.superBlockCertTypeMap = exports.certSlugTypeMap = exports.completionHours = exports.certIds = exports.SuperBlocks = exports.certTypes = void 0;
exports.certTypes = {
    frontEnd: 'isFrontEndCert',
    backEnd: 'isBackEndCert',
    dataVis: 'isDataVisCert',
    respWebDesign: 'isRespWebDesignCert',
    frontEndDevLibs: 'isFrontEndLibsCert',
    dataVis2018: 'is2018DataVisCert',
    jsAlgoDataStruct: 'isJsAlgoDataStructCert',
    apisMicroservices: 'isApisMicroservicesCert',
    infosecQa: 'isInfosecQaCert',
    qaV7: 'isQaCertV7',
    infosecV7: 'isInfosecCertV7',
    sciCompPyV7: 'isSciCompPyCertV7',
    dataAnalysisPyV7: 'isDataAnalysisPyCertV7',
    machineLearningPyV7: 'isMachineLearningPyCertV7',
    fullStack: 'isFullStackCert',
    relationalDatabaseV8: 'isRelationalDatabaseCertV8'
};
var SuperBlocks;
(function (SuperBlocks) {
    SuperBlocks["RespWebDesignNew"] = "2022/responsive-web-design";
    SuperBlocks["RespWebDesign"] = "responsive-web-design";
    SuperBlocks["JsAlgoDataStruct"] = "javascript-algorithms-and-data-structures";
    SuperBlocks["FrontEndDevLibs"] = "front-end-development-libraries";
    SuperBlocks["DataVis"] = "data-visualization";
    SuperBlocks["RelationalDb"] = "relational-database";
    SuperBlocks["BackEndDevApis"] = "back-end-development-and-apis";
    SuperBlocks["QualityAssurance"] = "quality-assurance";
    SuperBlocks["SciCompPy"] = "scientific-computing-with-python";
    SuperBlocks["DataAnalysisPy"] = "data-analysis-with-python";
    SuperBlocks["InfoSec"] = "information-security";
    SuperBlocks["MachineLearningPy"] = "machine-learning-with-python";
    SuperBlocks["CodingInterviewPrep"] = "coding-interview-prep";
})(SuperBlocks = exports.SuperBlocks || (exports.SuperBlocks = {}));
exports.certIds = {
    legacyFrontEndChallengeId: '561add10cb82ac38a17513be',
    legacyBackEndChallengeId: '660add10cb82ac38a17513be',
    legacyDataVisId: '561add10cb82ac39a17513bc',
    legacyInfosecQaId: '561add10cb82ac38a17213bc',
    legacyFullStackId: '561add10cb82ac38a17213bd',
    respWebDesignId: '561add10cb82ac38a17513bc',
    frontEndDevLibsId: '561acd10cb82ac38a17513bc',
    dataVis2018Id: '5a553ca864b52e1d8bceea14',
    jsAlgoDataStructId: '561abd10cb81ac38a17513bc',
    apisMicroservicesId: '561add10cb82ac38a17523bc',
    qaV7Id: '5e611829481575a52dc59c0e',
    infosecV7Id: '5e6021435ac9d0ecd8b94b00',
    sciCompPyV7Id: '5e44431b903586ffb414c951',
    dataAnalysisPyV7Id: '5e46fc95ac417301a38fb934',
    machineLearningPyV7Id: '5e46fc95ac417301a38fb935',
    relationalDatabaseV8Id: '606243f50267e718b1e755f4'
};
exports.completionHours = {
    [exports.certTypes.frontEnd]: 400,
    [exports.certTypes.backEnd]: 400,
    [exports.certTypes.dataVis]: 400,
    [exports.certTypes.infosecQa]: 300,
    [exports.certTypes.fullStack]: 1800,
    [exports.certTypes.respWebDesign]: 300,
    [exports.certTypes.frontEndDevLibs]: 300,
    [exports.certTypes.jsAlgoDataStruct]: 300,
    [exports.certTypes.dataVis2018]: 300,
    [exports.certTypes.apisMicroservices]: 300,
    [exports.certTypes.qaV7]: 300,
    [exports.certTypes.infosecV7]: 300,
    [exports.certTypes.sciCompPyV7]: 300,
    [exports.certTypes.dataAnalysisPyV7]: 300,
    [exports.certTypes.machineLearningPyV7]: 300,
    [exports.certTypes.relationalDatabaseV8]: 300
};
exports.certSlugTypeMap = {
    // legacy
    'legacy-front-end': exports.certTypes.frontEnd,
    'legacy-back-end': exports.certTypes.backEnd,
    'legacy-data-visualization': exports.certTypes.dataVis,
    // Keep these slugs the same so we don't
    // break existing links
    'information-security-and-quality-assurance': exports.certTypes.infosecQa,
    'full-stack': exports.certTypes.fullStack,
    // modern
    [SuperBlocks.RespWebDesign]: exports.certTypes.respWebDesign,
    [SuperBlocks.JsAlgoDataStruct]: exports.certTypes.jsAlgoDataStruct,
    [SuperBlocks.FrontEndDevLibs]: exports.certTypes.frontEndDevLibs,
    [SuperBlocks.DataVis]: exports.certTypes.dataVis2018,
    [SuperBlocks.BackEndDevApis]: exports.certTypes.apisMicroservices,
    'quality-assurance-v7': exports.certTypes.qaV7,
    'information-security-v7': exports.certTypes.infosecV7,
    'scientific-computing-with-python-v7': exports.certTypes.sciCompPyV7,
    'data-analysis-with-python-v7': exports.certTypes.dataAnalysisPyV7,
    'machine-learning-with-python-v7': exports.certTypes.machineLearningPyV7,
    'relational-database-v8': exports.certTypes.relationalDatabaseV8
};
exports.superBlockCertTypeMap = {
    // legacy
    'legacy-front-end': exports.certTypes.frontEnd,
    'legacy-back-end': exports.certTypes.backEnd,
    'legacy-data-visualization': exports.certTypes.dataVis,
    'information-security-and-quality-assurance': exports.certTypes.infosecQa,
    'full-stack': exports.certTypes.fullStack,
    // modern
    [SuperBlocks.RespWebDesign]: exports.certTypes.respWebDesign,
    [SuperBlocks.JsAlgoDataStruct]: exports.certTypes.jsAlgoDataStruct,
    [SuperBlocks.FrontEndDevLibs]: exports.certTypes.frontEndDevLibs,
    [SuperBlocks.DataVis]: exports.certTypes.dataVis2018,
    [SuperBlocks.BackEndDevApis]: exports.certTypes.apisMicroservices,
    [SuperBlocks.QualityAssurance]: exports.certTypes.qaV7,
    [SuperBlocks.InfoSec]: exports.certTypes.infosecV7,
    [SuperBlocks.SciCompPy]: exports.certTypes.sciCompPyV7,
    [SuperBlocks.DataAnalysisPy]: exports.certTypes.dataAnalysisPyV7,
    [SuperBlocks.MachineLearningPy]: exports.certTypes.machineLearningPyV7,
    [SuperBlocks.RelationalDb]: exports.certTypes.relationalDatabaseV8,
    // post-modern
    // TODO: use enum
    '2022/responsive-web-design': exports.certTypes.respWebDesign
};
exports.certTypeIdMap = {
    [exports.certTypes.frontEnd]: exports.certIds.legacyFrontEndChallengeId,
    [exports.certTypes.backEnd]: exports.certIds.legacyBackEndChallengeId,
    [exports.certTypes.dataVis]: exports.certIds.legacyDataVisId,
    [exports.certTypes.infosecQa]: exports.certIds.legacyInfosecQaId,
    [exports.certTypes.fullStack]: exports.certIds.legacyFullStackId,
    [exports.certTypes.respWebDesign]: exports.certIds.respWebDesignId,
    [exports.certTypes.frontEndDevLibs]: exports.certIds.frontEndDevLibsId,
    [exports.certTypes.jsAlgoDataStruct]: exports.certIds.jsAlgoDataStructId,
    [exports.certTypes.dataVis2018]: exports.certIds.dataVis2018Id,
    [exports.certTypes.apisMicroservices]: exports.certIds.apisMicroservicesId,
    [exports.certTypes.qaV7]: exports.certIds.qaV7Id,
    [exports.certTypes.infosecV7]: exports.certIds.infosecV7Id,
    [exports.certTypes.sciCompPyV7]: exports.certIds.sciCompPyV7Id,
    [exports.certTypes.dataAnalysisPyV7]: exports.certIds.dataAnalysisPyV7Id,
    [exports.certTypes.machineLearningPyV7]: exports.certIds.machineLearningPyV7Id,
    [exports.certTypes.relationalDatabaseV8]: exports.certIds.relationalDatabaseV8Id
};
exports.certTypeTitleMap = {
    [exports.certTypes.frontEnd]: 'Legacy Front End',
    [exports.certTypes.backEnd]: 'Legacy Back End',
    [exports.certTypes.dataVis]: 'Legacy Data Visualization',
    [exports.certTypes.infosecQa]: 'Legacy Information Security and Quality Assurance',
    [exports.certTypes.fullStack]: 'Legacy Full Stack',
    [exports.certTypes.respWebDesign]: 'Responsive Web Design',
    [exports.certTypes.frontEndDevLibs]: 'Front End Development Libraries',
    [exports.certTypes.jsAlgoDataStruct]: 'JavaScript Algorithms and Data Structures',
    [exports.certTypes.dataVis2018]: 'Data Visualization',
    [exports.certTypes.apisMicroservices]: 'Back End Development and APIs',
    [exports.certTypes.qaV7]: 'Quality Assurance',
    [exports.certTypes.infosecV7]: 'Information Security',
    [exports.certTypes.sciCompPyV7]: 'Scientific Computing with Python',
    [exports.certTypes.dataAnalysisPyV7]: 'Data Analysis with Python',
    [exports.certTypes.machineLearningPyV7]: 'Machine Learning with Python',
    [exports.certTypes.relationalDatabaseV8]: 'Relational Database'
};
exports.oldDataVizId = '561add10cb82ac38a17513b3';
